var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    [
      _c("router-view"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_loading,
              expression: "!is_loading",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1001" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }